@import '../../constants/styling_contstants.module.scss';

.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: index($zIndexPriorityElements, navigationOverlay);
    background-color: rgba(0, 0, 0, 0.48);
    transition:
        visibility 200ms ease-in-out,
        opacity 200ms ease-in-out;

    &.open {
        visibility: visible;
        opacity: 1;
    }
}
