@import '@lendoab/aphrodite/dist/tokens';

.logOutButtonMobile {
    display: flex;
    padding: map-get($spacing, 'small') map-get($spacing, 'xl');
    cursor: pointer;
    border-radius: map-get($border-radius, 'small');
    background-color: white;
    gap: map-get($spacing, 'xs');
    border-color: map-get($colors, 'blue-40');
    border-width: 2px;
    border-style: solid;
    justify-content: center;
}
