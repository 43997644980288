@import '@lendoab/aphrodite/dist/tokens';

.hamburgerButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    position: relative;
    background-color: white;
    border-radius: map-get($border-radius, 'small');
}

.hamburger span {
    background: map-get($colors, 'blue-40');
    height: 2px;
    width: 18px;
    border-radius: 2px;
    transition: all 0.15s ease-in-out;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18px;
    align-self: center;
}

.hamburger.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}
