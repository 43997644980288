@import '../../constants/styling_contstants.module.scss';
@import '@lendoab/aphrodite/dist/tokens.scss';

.subNavMobile {
    align-items: flex-start;
    transform: translateY(-100vh);
    transition: transform 200ms ease-in-out;
    width: 100%;
    height: 0;

    &.open {
        transform: translateY(0);
    }

    .content {
        // Top nav bar is 72px heigh, and it should have 24px padding bottom
        height: calc(100dvh - 72px - 24px);
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: 0 0 16px 16px;

        .innerContent {
            overflow-y: scroll;
        }
    }
}

.mobileSecondLevel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;

    &.mobileSecondLevelExpanded {
        max-height: none;
    }
}
