@import '@lendoab/aphrodite/dist/tokens';

.logOutButtonDesktop {
    display: flex;
    padding: map-get($spacing, 'small') map-get($spacing, 'xl');
    border-radius: map-get($border-radius, 'small');
    background-color: white;
    border: none;
    gap: map-get($spacing, 'xs');
    cursor: pointer;
    transition: 300ms ease-out;

    p {
        color: map-get($colors, 'blue-40');
    }

    svg path {
        fill: map-get($colors, 'blue-40');
    }

    &:hover {
        background-color: map-get($colors, 'blue-90');
    }

    &:active {
        p {
            color: map-get($colors, 'blue-30');
        }
        svg path {
            fill: map-get($colors, 'blue-30');
        }
    }
}
