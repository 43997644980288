@import '@lendoab/aphrodite/dist/tokens.scss';

.firstLevelNavigationLink {
    border: none;
    justify-content: space-between;

    svg {
        transition: 200ms ease-in-out;
    }

    @media (min-width: map-get($breakpoints, desktop)) {
        svg {
            margin-left: map-get($spacing, 'xs');
        }
    }
    @media (min-width: map-get($breakpoints, tablet)) {
        justify-content: flex-start;
        svg {
            margin-left: map-get($spacing, 'xs');
        }
    }

    &:hover {
        p {
            color: map-get($colors, 'blue-40');
        }
        svg path {
            fill: map-get($colors, 'blue-40');
        }
    }

    &.isOpen svg {
        transform: rotate(180deg);
    }

    &:active {
        -webkit-tap-highlight-color: transparent;
        p {
            color: map-get($colors, 'blue-30');
        }
        svg path {
            fill: map-get($colors, 'blue-30');
        }
    }
    &.active {
        position: relative;
        @media (min-width: map-get($breakpoints, desktop)) {
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: map-get($colors, 'blue-40');
                border-radius: 2px 2px 0px 0px;
            }
        }
    }
}
