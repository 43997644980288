@import '@lendoab/aphrodite/dist/tokens';
@import '../constants/styling_contstants.module.scss';

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: map-get($navbar, mobileHeight) * 1px;

    @media (min-width: map-get($breakpoints, desktop)) {
        padding-top: map-get($navbar, desktopTopBarHeight) * 1px;

        // TODO: Temporary implementation until the start page LENSE-6080 is done and the sub nav will always be visible.
        &:has([data-subnav-desktop]) {
            padding-top: map-get($navbar, desktopHeight) * 1px;
        }
    }
}

.containerLogin {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
