@import '@lendoab/aphrodite/dist/tokens.scss';

.subNavDesktop {
    position: absolute;
    background-color: white;
    border-radius: 12px;
    width: 349px;
    margin-top: map-get($spacing, 'medium');
    opacity: 0;
    visibility: hidden;
    transition: opacity ease-in-out 200ms;

    &.isExpanded {
        opacity: 1;
        visibility: visible;
    }

    .link {
        padding: map-get($spacing, 'small');
        margin: map-get($spacing, 'base');
        border-radius: 12px;
        display: flex;
        justify-content: space-between;

        &:hover {
            background-color: map-get($colors, 'blue-95');
            svg path {
                fill: map-get($colors, 'blue-30');
            }
        }
        &:active {
            background-color: map-get($colors, 'blue-90');
            svg path {
                fill: map-get($colors, 'blue-20');
            }
        }
    }
}

.scrolled {
    transition: opacity 200ms ease-in-out;
    box-shadow: 0px 2px 8px 1px #00000017;
}
